/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('/src/assets/fonts/poppins/poppins.css');
@import url('/src/assets/fonts/aller/aller.css');
@import url('/src/assets/fonts/rubik/rubik.css');
@import url('/src/assets/fonts/open-sans/open-sans.css');
// D:\WorkSpace\salik\salik-liva-ui\src\assets\fonts\poppins\poppins.css

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   list-style: none;
   // font-family: "poppins";
   font-family: "aller";
   // user-select: none;
   scrollbar-width: thin;
}



:root {
   --primary-color: #FF6B00 --logo-font-color:#2E2E2F
}

html {
      @media screen and (max-width:'390px') {
      font-size: .80rem;
      }

      @media screen and (min-width:'767px') {
         // font-size: .40rem;
         // font-size: .66rem;
         font-size: 80%;
      }
      @media screen and (min-width:'1024px') {

         // font-size: .53rem;
//          font-size: .66rem;
     }

      @media screen and (min-width:'1280px') {

         // font-size: .66rem;
      }

      @media screen and (min-width:'1535px') {

         // font-size: .80rem;
      }

      @media screen and (min-width:'1641px') {

         // font-size: .85rem;
         
      }

      @media screen and (min-width:'1920px') {

         font-size: 100%;
      }
   }

   .ngx-slider {
      margin: 0 !important;
      .ngx-slider-bar {
          background: white !important;
      }
    .ngx-slider-selection {
      background: #FF6900 !important;
    }
    .ngx-slider-pointer {
      background: transparent !important;
      height: 16px !important;
      width: 16px !important ;
outline: none !important;
      &:after {
        background: #FF6900 !important ;
        width: 16px !important;
        height: 16px !important;
        top: 7px !important;
        left: 0px !important;
        border-radius: 100% !important;
      }
    }

  }