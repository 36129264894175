@font-face {
    font-family: "rubik";
    font-weight: normal;
    font-size: normal;
    src: url('/assets/fonts/rubik/Rubik-Regular.ttf'),format('ttf');
}

@font-face {
    font-family: "rubik";
    font-weight: 500;
    font-size: normal;
    src: url('/assets/fonts/rubik/Rubik-Medium.ttf'),format('ttf');
}

@font-face {
    font-family: "rubik";
    font-weight: 600;
    font-size: normal;
    src: url('/assets/fonts/rubik/Rubik-SemiBold.ttf'),format('ttf');
}